export const scope = 'src.components.App.Common';

export const commonMessagesKeys = {
  application_title: `${scope}.application_title`,
  show: `${scope}.show`,
  hide: `${scope}.hide`,
  overview: `${scope}.overview`,
  seed_advisor: `${scope}.seed_advisor`,
  cas_users: `${scope}.cas_users`,
  seed_adviser_id: `${scope}.seed_adviser_id`,
  grower: `${scope}.grower`,
  growers: `${scope}.growers`,
  grower_id: `${scope}.grower_id`,
  farm_name: `${scope}.farm_name`,
  farm_address: `${scope}.farm_address`,
  grower_address: `${scope}.grower_address`,
  city: `${scope}.city`,
  state: `${scope}.state`,
  zip: `${scope}.zip`,
  phone_number: `${scope}.phone_number`,
  office_phone_optional: `${scope}.office_phone_optional`,
  cell_phone: `${scope}.cell_phone`,
  fax_optional: `${scope}.fax_optional`,
  preferred_email_optional: `${scope}.preferred_email_optional`,
  email: `${scope}.email`,
  email_address: `${scope}.email_address`,
  edit: `${scope}.edit`,
  cancel: `${scope}.cancel`,
  save: `${scope}.save`,
  not_assigned: `${scope}.not_assigned`,
  not_submitted: `${scope}.not_submitted`,
  not_eligible: `${scope}.not_eligible`,
  not_signed: `${scope}.not_signed`,
  signed: `${scope}.signed`,
  invalid_phone: `${scope}.invalid_phone`,
  grower_id_min_max_digits: `${scope}.grower_id_min_max_digits`,
  sms: `${scope}.sms`,
  na: `${scope}.na`,
  communicationPreferences: `${scope}.communicationPreferences`,
  paymentPreferences: `${scope}.paymentPreferences`,
  optin_pay: `${scope}.optin_pay`,
  optout_pay: `${scope}.optout_pay`,
  account_updates: `${scope}.account_updates`,
  mailing_address: `${scope}.mailing_address`,
  monthly_statements: `${scope}.monthly_statements`,
  paymentPreferences: `${scope}.paymentPreferences`,
  optin_pay: `${scope}.optin_pay`,
  optout_pay: `${scope}.optout_pay`,
  marketing_and_promotions: `${scope}.marketing_and_promotions`,
  paper_statements: `${scope}.paper_statements`,
  paper_by_mail: `${scope}.paper_by_mail`,
  standard_rates_may_apply: `${scope}.standard_rates_may_apply`,
  loading_message: `${scope}.loading_message`,
  my_user_profile: `${scope}.my_user_profile`,
  ssn: `${scope}.ssn`,
  federal_tax_id_optional: `${scope}.federal_tax_id_optional`,
  federal_tax_id: `${scope}.federal_tax_id`,
  references: `${scope}.references`,
  name: `${scope}.name`,
  grower_name: `${scope}.grower_name`,
  sa_name: `${scope}.sa_name`,
  sa_operation_name: `${scope}.sa_operation_name`,
  sa_fsap_id: `${scope}.sa_fsap_id`,
  grower_farm_name: `${scope}.grower_farm_name`,
  signature: `${scope}.signature`,
  print: `${scope}.print`,
  title: `${scope}.title`,
  date: `${scope}.date`,
  address: `${scope}.address`,
  application_id: `${scope}.application_id`,
  approved: `${scope}.approved`,
  rejected: `${scope}.rejected`,
  pending: `${scope}.pending`,
  success_account_updated: `${scope}.success_account_updated`,
  error_updating_seed_advisor_msg: `${scope}.error_updating_seed_advisor_msg`,
  duplicate_email_msg: `${scope}.duplicate_email_msg`,
  sa_ack_signed: `${scope}.sa_ack_signed`,
  view_doc_sign: `${scope}.view_doc_sign`,
  crop_edge_id: `${scope}.crop_edge_id`,
  sfid: `${scope}.sfid`,
  terms_and_conditions: `${scope}.terms_and_conditions`,
  support_email: `${scope}.support_email`,
  support_phone: `${scope}.support_phone`,
  notification_failed: `${scope}.notification_failed`,
  bulletin: `${scope}.bulletin`,
  zd_partnership: `${scope}.zd_partnership`,
  sales_rep: `${scope}.sales_rep`,
  grower_payments: `${scope}.grower_payments`,
  grower_credit_histories: `${scope}.grower_credit_histories`,
  next_steps: `${scope}.next_steps`,
  profile: `${scope}.profile`,
  profile_incomplete_msg: `${scope}.profile_incomplete_msg`,
  update_profile: `${scope}.update_profile`,
  goto_profile: `${scope}.goto_profile`,
  server_error: `${scope}.server_error`,
  statements: `${scope}.statements`,
  resources: `${scope}.resources`,
  no_resources_available: `${scope}.no_resources_available`,
  all_signup_options: `${scope}.all_signup_options`,
  credit_line_approved: `${scope}.credit_line_approved`,
  credit_line_used: `${scope}.credit_line_used`,
  remaining_balance: `${scope}.remaining_balance`,
  type: `${scope}.type`,
  source: `${scope}.source`,
  payment_amount: `${scope}.payment_amount`,
  status: `${scope}.status`,
  other_informations: `${scope}.other_informations`,
  ext_credits_tandc: `${scope}.ext_credits_tandc`,
  payment_due: `${scope}.payment_due`,
  switch_to: `${scope}.switch_to`,
  seed_care: `${scope}.seed_care`,
  acknowledgement: `${scope}.acknowledgement`,
  ack_signed: `${scope}.ack_signed`,
  ack_declined: `${scope}.ack_declined`,
  seed_care_amount: `${scope}.seed_care_amount`,
  seed_treater: `${scope}.seed_treater`,
  total_extended_terms_approved: `${scope}.total_extended_terms_approved`,
  total_extended_terms_used: `${scope}.total_extended_terms_used`,
  total_extended_terms_requested: `${scope}.total_extended_terms_requested`,
  total_treatment_fee: `${scope}.total_treatment_fee`,
  total_treatment_fee_primary_sa: `${scope}.total_treatment_fee_primary_sa`,
  total_treatment_fee_secondary_sa: `${scope}.total_treatment_fee_secondary_sa`,
  remaining_terms_available: `${scope}.remaining_terms_available`,
  welcome: `${scope}.welcome`,
  welcome_back: `${scope}.welcome_back`,
  apply_for_ga: `${scope}.apply_for_ga`,
  financial_year_selection_label: `${scope}.financial_year_selection_label`,
  no_history_available: `${scope}.no_history_available`,
  no_ga_history_available_selected_year: `${scope}.no_ga_history_available_selected_year`,
  no_payment_trasaction_history_available_selected_year: `${scope}.no_payment_trasaction_history_available_selected_year`,
  primary_sa_assigned: `${scope}.primary_sa_assigned`,
  note_box_title: `${scope}.note_box_title`,
  note_box_text_1: `${scope}.note_box_text_1`,
  note_box_text_2: `${scope}.note_box_text_2`,
  note_box_image_alt: `${scope}.note_box_image_alt`,
  statement_selection_verbiage: `${scope}.statement_selection_verbiage`,

  signup: {
    first_name: `${scope}.signup.first_name`,
    middle_initial: `${scope}.signup.middle_initial`,
    last_name: `${scope}.signup.last_name`,
    email: `${scope}.signup.email`,
    password: `${scope}.signup.password`,
    confirm: `${scope}.signup.confirm`,
  },

  buttons: {
    save: `${scope}.buttons.save`,
    close: `${scope}.buttons.close`,
    cancel: `${scope}.buttons.cancel`,
    continue: `${scope}.buttons.continue`,
    ok: `${scope}.buttons.ok`,
    continue_working: `${scope}.buttons.continue_working`,
    select: `${scope}.buttons.select`,
    search: `${scope}.buttons.search`,
    remove: `${scope}.buttons.remove`,
    view: `${scope}.buttons.view`,
    apply_now: `${scope}.buttons.apply_now`,
    clear_form: `${scope}.buttons.clear_form`,
    submit: `${scope}.buttons.submit`,
    update: `${scope}.buttons.update`,
    export: `${scope}.buttons.export`,
    export_all: `${scope}.buttons.export_all`,
    export_filtered: `${scope}.buttons.export_filtered`,
    send: `${scope}.buttons.send`,
    send_email: `${scope}.buttons.send_email`,
    makePayment: `${scope}.buttons.makePayment`,
    leave_this_page: `${scope}.buttons.leave_this_page`,
    download: `${scope}.buttons.download`,
    get_started: `${scope}.buttons.get_started`,
    go_back: `${scope}.buttons.go_back`,
    confirm_and_continue: `${scope}.buttons.confirm_and_continue`,
    save_and_continue: `${scope}.buttons.save_and_continue`,
  },
  error: {
    invalid_zip: `${scope}.error.invalid_zip`,
    invalid_ssn: `${scope}.error.invalid_ssn`,
    invalid_tax_id: `${scope}.error.invalid_tax_id`,
    invalid_grower_id: `${scope}.error.invalid_grower_id`,
    positive_financials: `${scope}.error.positive_financials`,
    application_failed: `${scope}.error.application_failed`,
  },
};

export const commonParamMsg = {
  welcome_header: `${scope}.welcome_header`,
  welcome_back_header: `${scope}.welcome_back_header`,
  error: {
    required: `${scope}.error.required`,
    invalid_name: `${scope}.error.invalid_name`,
    invalid_grower_id: `${scope}.error.invalid_grower_id`,
  },
};
